<template>
  <main class="blog-post">
    <div class="horiz-container default-grid">
      <FancyBreadcrumbs class="blog-post__breadcrumbs" :links="[
          { name: 'Блог', to: { name: 'blog' } },
          { name: post.title, to: { name: 'blog-post', params: { slug: post.slug ?? post.id } } }
        ]" />
      <aside class="blog-post__sidebar">
        <div class="blog-post__author">
          <AvatarPlaceholder v-if="post.mentor && !post.mentor.avatar_url" :data="post.mentor" class="blog-post__author__avatar" />
          <img v-else :src="post.mentor?.avatar_url ?? Logo" :alt="authorName" class="blog-post__author__avatar">
          <div class="blog-post__author__info">
            <div class="blog-post__author__name">{{ authorName }}</div>
            <BaseButton v-if="post.mentor" class="blog-post__author__action" small stretch color-primary :to="{ name: 'mentor-book', params: { id: post.mentor.id } }" target="_blank">Забронировать занятие</BaseButton>
          </div>
        </div>
      </aside>
      <div class="blog-post__body">
        <div class="blog-post__meta">
          <ChipList :items="chips" class="blog-post__tags" not-clickable />
          <div class="blog-post__date"><time :datetime="post.created_at">{{ dayjs(post.created_at).format('D.MM.YYYY') }}</time></div>
        </div>
        <h1 class="blog-post__title">{{ post.title }}</h1>
        <img :src="post.image.url" :alt="post.title" class="blog-post__image">
        <div class="blog-post__content blog-content">
          <ContentRenderer :content="post.content" />
        </div>

        <BecomeMentorBanner class="blog-post__banner" />
        <SectionWithAction v-if="related?.length >= 2" class="blog-post__related">
          <template #title>Похожие статьи по&nbsp;данной теме</template>
          <template #default>
            <BlogList :posts="related.slice(0, 2)" />
          </template>
        </SectionWithAction>
      </div>
    </div>
  </main>
</template>

<script setup>
import ContentRenderer from '@/components/common/ContentRenderer'
import { getPost, searchPosts } from '~/api/blog'
import Logo from '@/assets/img/icons/logo.svg'

const route = useRoute()
const dayjs = useDayjs()

const { data: post } = await useAsyncData(() => getPost(route.params.slug))
if (!post.value) throw createNotFoundError()

const chips = computed(() => [post.value.mentor ? 'От ментора' : 'От редакции'].concat(post.value.tags))

const authorName = computed(() => post.value.mentor ? post.value.mentor.first_name + ' ' + post.value.mentor.last_name : 'Digital mentoring')

useHead({
  title: post.value?.seo_title ?? post.value.title
})

if (post.value?.seo_description || post.value?.seo_keywords)
  useServerSeoMeta({
    description: post.value?.seo_description,
    keywords: post.value?.seo_keywords
  })

const { data: related } = await useAsyncData(() => searchPosts({
  per_page: 2,
  like_by: post.value.id
}), { transform: d => d.data, default: () => [] })
</script>

<style scoped lang="scss">
.blog-post {
  &__breadcrumbs {
    grid-column: span 12;
    margin-bottom: 24px;
  }
  &__sidebar {
    grid-column: span 3;
    @media (max-width: 920px) {
      grid-column: span 12;
      padding-bottom: 24px;
      border-bottom: 1px solid var(--color-separator);
      margin-bottom: 24px;
    }
  }
  &__author {
    display: flex;
    flex-direction: column;
    gap: 16px;
    @media (max-width: 920px) {
      flex-direction: row;
      gap: 18px;
      align-items: stretch;
    }

    &__avatar {
      width: 100%;
      aspect-ratio: 1;
      border-radius: 16px;
      object-fit: cover;

      @media (max-width: 920px) {
        width: 84px;
        border-radius: 8px;
      }
    }
    &__info {
      display: flex;
      flex-direction: column;
      gap: 16px;
      justify-content: space-between;
    }
    &__name {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      color: var(--color-elements-primary);
      gap: 4px 16px;
      flex-wrap: wrap;

      &::before {
        content: 'Автор';
        color: var(--color-elements-secondary);
        font-weight: 500;
      }
    }
  }
  &__body {
    grid-column: span 9;
    @media (max-width: 920px) {
      grid-column: span 12;
    }
  }
  &__meta {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 16px;
    @media (max-width: 920px) {
      margin-bottom: 18px;
      flex-direction: column;
      gap: 24px;
    }
  }
  &__date {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: var(--color-elements-secondary);
  }
  &__title {
    font-size: 36px;
    font-weight: 900;
    line-height: 48px;
    text-transform: uppercase;
    color: var(--color-bg-dark);
    margin-bottom: 32px;

    @media (max-width: 920px) {
      font-size: 24px;
      line-height: 30px;
      text-transform: none;
      color: var(--color-black);
    }
  }
  &__image {
    height: 396px;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 32px;
    background: var(--color-primary);
    @media (max-width: 920px) {
      height: 205px;
      border-radius: 0;
    }
  }
  &__banner {
    margin-top: 32px;
    @media (max-width: 920px) {
      display: none;
    }
  }
  &__related {
    margin-top: 80px;
    @media (max-width: 920px) {
      display: none;
    }
  }
}
</style>
